import { LiteralUnion } from 'prettier'
import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'

export type SymbolHistoryResourceData = {
  /** E.g. 1607040000000 millionseconds */
  time: number
  open: number
  high: number
  low: number
  close: number
  volume: number
}

// 暫定以日為單位
// intraday & fromTo 設定擇一
export const useSymbolHistoryResource = (options?: {
  symbol: string
  intraday?: boolean
  fromTo?: [number, number]
  resolution?: LiteralUnion<'1D' | '1H', string>
}) => {
  const symbol = options?.symbol || '2330'
  const resolution = options?.resolution || '1D'
  const intraday = options?.intraday || undefined
  const fromTo = intraday
    ? [undefined, undefined]
    : options?.fromTo && options.fromTo.length === 2
    ? options.fromTo
    : [undefined, undefined]

  const api = urlcat(`${apirc.tvAPIs.baseUrl}/history`, {
    symbol,
    resolution,
    from: fromTo[0],
    to: fromTo[1],
    ws: true,
    intraday,
  })

  const res = useSWR<SymbolHistoryResourceData[]>(api, {
    revalidateOnFocus: false,
    refreshInterval: 10000,
  })

  return res
}

// 暫定以日為單位，call only once
export const useSymbolHistoryOnceResource = (options?: {
  symbol: string
  fromTo: [number, number]
  resolution?: LiteralUnion<'1D' | '1H', string>
}) => {
  const symbol = options?.symbol || '2330'
  const resolution = options?.resolution || '1D'
  const fromTo = options?.fromTo && options.fromTo.length === 2 ? options.fromTo : [0, 0]
  const api = urlcat(`${apirc.tvAPIs.baseUrl}/history`, {
    symbol,
    resolution,
    from: fromTo[0],
    to: fromTo[1],
    ws: true,
  })

  const res = useSWR<SymbolHistoryResourceData[]>(api, {
    revalidateOnFocus: false,
  })

  return res
}
