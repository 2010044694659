import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { toPercentage } from '~/utils/toPercentage'

/** 將報價變成人類使用者易讀的格式 */
export const processQuoteToReadable = (
  data: Socket.ValueOfOHLC | Signalr.ValueOfOHLC | undefined,
  isBaseOnToday?: boolean,
): Change => {
  const changes: Change = {
    delta: 0,
    openChangePercent: 0,
    highChangePercent: 0,
    lowChangePercent: 0,
    closeChangePercent: 0,
    openChange: 0,
    highChange: 0,
    lowChange: 0,
    closeChange: 0,
    deltaChangeString: '0',
    closeChangePercentString: '0',
  }

  if (!data) return changes

  const baseRef = isBaseOnToday ? data.open : data.prevRef
  /** 收盤變動%數 */
  changes.delta = parseFloat((data.close - baseRef).toFixed(3))
  changes.deltaChangeString = changes.delta > 0 ? `+${changes.delta}` : `${changes.delta}`

  /** 開盤變動%數 */
  changes.openChange = parseFloat((data.open - baseRef).toFixed(3))
  changes.openChangePercent = toPercentage(changes.openChange, baseRef)

  /** 高點點變動%數 */
  changes.highChange = parseFloat((data.high - baseRef).toFixed(3))
  changes.highChangePercent = toPercentage(changes.highChange, baseRef)

  /** 低點變動%數 */
  changes.lowChange = parseFloat((data.low - baseRef).toFixed(3))
  changes.lowChangePercent = toPercentage(changes.lowChange, baseRef)

  /** 變動%數 */
  changes.closeChange = parseFloat((data.close - baseRef).toFixed(3))
  changes.closeChangePercent = toPercentage(changes.closeChange, baseRef)

  /** 變動%數並顯示 正值,負值 */
  changes.closeChangePercentString =
    changes.closeChangePercent > 0
      ? `+${changes.closeChangePercent.toFixed(2)}%`
      : `${changes.closeChangePercent.toFixed(2)}%`

  return changes
}

export type Change = {
  delta: number
  deltaChangeString: string
  openChangePercent: number
  highChangePercent: number
  lowChangePercent: number
  closeChangePercent: number
  openChange: number
  highChange: number
  lowChange: number
  closeChange: number
  closeChangePercentString: string
  contributionPoints?: number
}

export type ChangeNumber = {
  delta: number
  deltaChangeString: string
  openChangePercent: number
  highChangePercent: number
  lowChangePercent: number
  closeChangePercent: number
  openChange: number
  highChange: number
  lowChange: number
  closeChange: number
}
