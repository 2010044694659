import { Dayjs } from 'dayjs'
import dayAPI from '~/utils/dayAPI'

/** 台灣股票與指數 09:00-13:30 */
export const twStockTicks = (date: Dayjs): [number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(9, 'hour').unix(),
    baseDate.add(10, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(12, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
  ]
}

/** 台灣期貨T盤 08:45-13:45 */
export const twAMFuturesTicks = (date: Dayjs): [number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').add(45, 'minute').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(10, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(12, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
  ]
}

/** 台灣期貨T+1盤 15:00-05:00 */
export const twPMFuturesTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').unix(),
  ]
}

/** 台灣期貨T/T+1盤 自動切換 08:45-13:45, 15:00-05:00 */
export const twAutoSessionTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  const isWeekend = baseDate.day() === 6 || baseDate.day() === 7
  const isAmSession = date.isBetween(baseDate.clone().hour(8).minute(45), baseDate.clone().hour(15))
  const displayAM = isAmSession && !isWeekend
  if (displayAM)
    return [
      baseDate.add(8, 'hour').add(45, 'minute').unix(),
      baseDate.add(9, 'hour').unix(),
      baseDate.add(10, 'hour').unix(),
      baseDate.add(11, 'hour').unix(),
      baseDate.add(12, 'hour').unix(),
      baseDate.add(13, 'hour').unix(),
      baseDate.add(13, 'hour').add(45, 'minute').unix(),
      baseDate.add(13, 'hour').add(45, 'minute').unix(),
    ]
  else
    return [
      baseDate.add(15, 'hour').unix(),
      baseDate.add(17, 'hour').unix(),
      baseDate.add(19, 'hour').unix(),
      baseDate.add(21, 'hour').unix(),
      baseDate.add(23, 'hour').unix(),
      baseDate.add(1, 'day').add(1, 'hour').unix(),
      baseDate.add(1, 'day').add(3, 'hour').unix(),
      baseDate.add(1, 'day').add(5, 'hour').unix(),
    ]
}

/**
 * CME交易所 06:00-05:00 適用商品 美指數: YM,NQ,EX,RTY, 能源類: CL,GC,HG,SI,NG,PL, 美國公債類: TN,TU,FV,TY,US,UB, 外匯類:
 * DX,AD,BP,CD,EC,JY,SF
 */
export const cmeFuturesTicks = (
  date: Dayjs,
): [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(6, 'hour').unix(),
    baseDate.add(7, 'hour').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').unix(),
  ]
}

/** CBOT交易所 黃豆,黃豆油,小麥 08:00~02:20 */
export const cbotFuturesTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(2, 'hour').add(20, 'minute').unix(),
  ]
}

/** CBOT交易所 玉米 08:00~02:20 暫停交易20:45~21:30 */
export const cbotCTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(2, 'hour').add(20, 'minute').unix(),
  ]
}

/** NYBOT 咖啡 16:15~01:30 */
export const nybotKCTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(16, 'hour').add(15, 'minute').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(18, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(20, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(22, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(0, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').add(30, 'minute').unix(),
  ]
}

/** NYBOT 11號國際糖 15:30~01:00 */
export const nybotSBTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(15, 'hour').add(30, 'minute').unix(),
    baseDate.add(16, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(18, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(20, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(22, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(0, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
  ]
}

/** NYBOT 可可豆 16:45~01:30 */
export const nybotCCTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(16, 'hour').add(45, 'minute').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(18, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(20, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(22, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(0, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').add(30, 'minute').unix(),
  ]
}

/** SGX 富時台灣指數 08:45~16:35 14:15~05:15 */
export const sgxTWNTicks = (
  date: Dayjs,
): [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').add(45, 'minute').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').add(15, 'minute').unix(),
  ]
}

/** SGX 富時中國A50 09:00~16:35 17:00~05:15 */
export const sgxCNTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').add(15, 'minute').unix(),
  ]
}

/** SGX 日經指數225 07:30~14:30 14:55~05:15 */
export const sgxNKTicks = (
  date: Dayjs,
): [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(7, 'hour').add(30, 'minute').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').add(15, 'minute').unix(),
  ]
}

/** SGX 印度指數 09:00~18:15 18:40~05:15 */
export const sgxINTicks = (
  date: Dayjs,
): [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(9, 'hour').add(0, 'minute').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(2, 'hour').unix(),
    baseDate.add(1, 'day').add(4, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').add(15, 'minute').unix(),
  ]
}

/** HKF H股,恆生 09:15~12:00 13:00~16:30 17:15~03:00 */
export const hkfFuturesTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(9, 'hour').add(15, 'minute').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
  ]
}

/** MSCI台灣指數 08:45~16:30 17:15~03:00 */
export const hkfMTWTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').add(45, 'minute').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
  ]
}

/** 歐交所 08:15~05:00 */
export const eurexFuturesTicks = (
  date: Dayjs,
): [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(8, 'hour').add(15, 'minute').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(11, 'hour').unix(),
    baseDate.add(13, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(17, 'hour').unix(),
    baseDate.add(19, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(5, 'hour').unix(),
  ]
}

/** 美股 21:30~04:00 */
export const usStockTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(21, 'hour').add(30, 'minute').unix(),
    baseDate.add(23, 'hour').unix(),
    baseDate.add(1, 'day').unix(),
    baseDate.add(1, 'day').add(1, 'hour').unix(),
    baseDate.add(1, 'day').add(2, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(4, 'hour').unix(),
  ]
}

/** 06:00~06:00 */
export const allDayTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.hour() >= 6 ? date.startOf('day') : date.startOf('day').add(-1, 'day')
  return [
    baseDate.add(6, 'hour').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(12, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(18, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(24, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(6, 'hour').unix(),
  ]
}

/** 06:00~06:00 */
export const foreignTicks = (
  date: Dayjs,
): [number, number, number, number, number, number, number, number, number] => {
  const baseDate = date.startOf('day')
  return [
    baseDate.add(6, 'hour').unix(),
    baseDate.add(9, 'hour').unix(),
    baseDate.add(12, 'hour').unix(),
    baseDate.add(15, 'hour').unix(),
    baseDate.add(18, 'hour').unix(),
    baseDate.add(21, 'hour').unix(),
    baseDate.add(24, 'hour').unix(),
    baseDate.add(1, 'day').add(3, 'hour').unix(),
    baseDate.add(1, 'day').add(6, 'hour').unix(),
  ]
}
